<template>
    <div class="starting-space" :class="{
        'bg-orange': color == 'orange',
        'bg-green': color == 'green',
        'bg-blue': color == 'blue',
        'bg-yellow': color == 'yellow',

        'top': top,
        'bottom': !top,
        'left': left,
        'right': !left
    }">
        <playing-field v-for="i in piecesOnStartingSpace" :key="i" :avatar-standing="[player.selectedAvatar]" />
        <playing-field v-for="i in (4 - piecesOnStartingSpace)" :key="i" />

        <!-- <div style="background: lightblue; opacity: .9; position: absolute; top: 0; left: 0;">
            <pre>
                {{ player.gamePieces }} || {{ player.startPosition }}
            </pre>
        </div> -->
    </div>
</template>

<script>
    export default {
        props: ["color", "top", "left", "player"],
        computed: {
            piecesOnStartingSpace(){
                let counter = 0;

                this.player?.gamePieces.forEach(piece => {
                    if(piece.position == 0){
                        counter++;
                    }
                });

                return counter;
            }
        }
    }
</script>

<style lang="scss" scoped>
    .starting-space{
        border-radius: 25px;
        padding: 55px;
        width: 250px;
        height: 250px;

        position: absolute;

        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        gap: 10px;

        &:before{
            content: "";
            border: 3px solid #fff;
            border-radius: 20px;
            position: absolute;
            top: 25px;
            bottom: 25px;
            left: 25px;
            right: 25px;
        }
    }

    .field{
        background: blue !important;
    }

    .top{ top: 0; }
    .bottom{ bottom: 0; }
    .left{ left: 0; }
    .right{ right: 0; }

    .bg-orange{ background: #E25C00; }
    .bg-green{ background: #9EC000; }
    .bg-blue{ background: #699CD6; }
    .bg-yellow{ background: #FAE400; }

    @media only screen and (max-width: 1230px),
            only screen and (max-height: 960px){
        .starting-space{ 
            width: 220px;
            height: 220px;
            justify-content: center;
            align-items: center;
        }
    }

    @media only screen and (max-width: 1340px),
            only screen and (max-height: 850px){
        .starting-space{
            padding: 30px;
            width: 180px;
            height: 180px;

            &:before{
                top: 15px;
                bottom: 15px;
                left: 15px;
                right: 15px;
            }
        }
    }
</style>