<template>
    <div class="start-menu-modal layout-bg">
        <div class="container">
            <div class="welcome">
                <span>
                    <h1 class="title">{{langConfig[lang].start.title}}
                        <span class="red">&nbsp{{langConfig[lang].start.icdf[0]}}</span>
                        <span class="orange">&nbsp{{langConfig[lang].start.icdf[1]}}</span>
                        <span class="yellow">&nbsp{{langConfig[lang].start.icdf[2]}}</span>
                        <span class="green">&nbsp{{langConfig[lang].start.icdf[3]}}</span>
                        <img class="vawe" :src="'../assets/img/icons/wave.gif'">
                    </h1>
                    
                    <p v-html="langConfig[lang].start.subtitleText" class="text"></p>
                </span>

                <div class="works">
                    <h2 class="subtitle">{{langConfig[lang].start.works.title}}</h2>
                    <ol class="list">
                        <li v-for="i in 4" v-html="langConfig[lang].start.works.list[i-1]"></li>
                            <ul class="child-list">
                                <li><font-awesome-icon icon="eye" class="icon red"></font-awesome-icon>{{langConfig[lang].start.works.childList[0]}}</li>
                                <li><font-awesome-icon icon="question" class="icon yellow"></font-awesome-icon>{{langConfig[lang].start.works.childList[1]}}</li>
                                <li><font-awesome-icon icon="check" class="icon green"></font-awesome-icon>{{langConfig[lang].start.works.childList[2]}}</li>
                            </ul>
                        <li v-html="langConfig[lang].start.works.list[4]"></li>
                    </ol>
                </div>
                <strong v-html="langConfig[lang].start.bottomText" style="color: red;"></strong>
                <div class="sponzors">
                    <img style="height: 35px" :src="'../assets/img/icons/weilovka.jpg'">
                    <img style="height: 43px" :src="'../assets/img/icons/europe.png'">
                    <img style="height: 42px" :src="'../assets/img/icons/icodethefuture.png'">
                </div>
            </div>

            <div class="start">
                <img class="animated-icon" :src="'../assets/img/icons/code.gif'">

                <h1>{{langConfig[lang].start.name}}</h1>

                <div class="select-players">
                    <div class="player" v-for="i in 4" :key="i">
                        <div class="player-wrapper">
                            <div v-if="this.toggleIconsSelect == i - 1" class="icons-selector">
                                <img v-for="(images, index) in 4" :key="index" @click="changeSelectedAvatar(i - 1, images)" class="selector-icon" :src="'../assets/img/avatars/avatar-' + images + '.png'">
                            </div>
        
                            <div class="img-selector" @click="toggleIconsSelect = i - 1" v-click-outside-element="closeAvatarSelection">
                                <img class="img-selected" :src="returnIcon(i)">
                            </div>
        
                            <input v-model="this.$parent.players[i - 1].username" maxlength="10" class="username" :class="{ 'invalid': this.$parent.players[i - 1].username && duplicateUsernames == this.$parent.players[i - 1].username.toLowerCase() }" :placeholder="langConfig[lang].start.selectNames">
                        </div>
                    </div>
                </div>
                
                <button class="start-game" :class="{ disabled: submitDisabled }" @click="$emit('close')">{{langConfig[lang].start.startGame}}</button>
            </div>
        </div>
    </div>
</template>

<script>
import configLang from "../assets/config.json";
    export default {
        props: ["lang"],
        data() {
            return {
                langConfig: configLang,
                toggleIconsSelect: null,
                botnames: ['Bot John', 'Bot Jacob', 'Bot Patt', 'Bot Radly'],
                duplicateUsernames: false,
            }
        },
        methods: {
            changeSelectedAvatar(playerNumber, imageSelected){
                let players = Object.values(this.$parent.players).filter(item => item.selectedAvatar == imageSelected);

                if(players.length > 0){
                    if(players[0].selectedAvatar > 0 && players[0].selectedAvatar <= 4){
                        players[0].selectedAvatar = this.$parent.players[playerNumber].selectedAvatar;
                        this.$parent.players[playerNumber].selectedAvatar = imageSelected;
                    }else{
                        this.$parent.players[playerNumber].selectedAvatar = 5;
                        this.$parent.players[playerNumber].username = this.botnames[Math.floor(Math.random() * this.botnames.length)];
                    }
                }else{
                    this.$parent.players[playerNumber].selectedAvatar = imageSelected;
                }

            },
            closeAvatarSelection(event){
                if(event.target.className != "img-selector" && event.target.className != "img-selected"){
                    this.toggleIconsSelect = null;
                }
            },
            returnIcon(i){
                return '../assets/img/avatars/avatar-' + this.$parent.players[i - 1].selectedAvatar + '.png';
            }
        },
        computed: {
            submitDisabled(){
                let disabled = false;
                let usernames = [];
                this.duplicateUsernames = false;

                this.$parent.players.forEach(player => {

                    if(!player.username){
                        disabled = true;
                    }else if(usernames.includes(player.username.toLowerCase())){
                        disabled = true;

                        this.duplicateUsernames = player.username.toLowerCase();
                    }else{
                        usernames.push(player.username.toLowerCase());
                    }
                });

                return disabled;
            }
        }
    };
</script>

<style lang="scss" scoped>

.layout-bg{
    background: rgba(0, 0, 0, 0.842);
    width: 100%;
    height: 100%;

    overflow-y: auto;

    position: absolute;
    top: 0;
    left: 0;
    z-index: 999;

    display: flex;
    justify-content: center;
    align-items: center;
    
    padding: 30px;
}

.start-menu-modal{
    .container{
        width: 100%;
        max-width: 1400px;
    
        display: flex;
        align-items: row;
        gap: 10px;
    }

    .welcome{
        background: #fff;
        border-radius: 15px;
        // height: 100%;
        // width: 65%;
        flex-grow: 1;
        width: 100%;
        padding: 30px;

        display: flex;
        flex-direction: column;
        gap: 30px;

        .title {
            display: flex;
            align-items: center;
            font-size: 28px;
            font-weight: bolder;

            .vawe{
                height: 50px;
            }

            .red{
               color: #A35353; 
            }

            .orange{
                color: #C85515;
            }

            .yellow{
                color: #D79B41;
            }

            .green{
                color: #2E9C24;
            }
        }

        .text{
            font-size: 19px;
            text-align: justify;

            .bold{
                color: rgb(54, 53, 53);
                font-weight: 600;
            }
        }

        strong{
            .btn{
                background-color: #136DDC;
                text-align: center;
                border-radius: 22px;
                padding: 4px 10px;
                color: white;
                border: none;
                cursor: pointer;
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
            }

            .br5 {
                border-radius: 10px !important;
            }
        }

        .works{
            display: flex;
            flex-direction: column;
            margin-top: 15px;
            margin-bottom: 15px;

            .subtitle{
                height: 35px;
            }

            .list{
                font-size: 19px;
                margin-left: 18px;

                .btn{
                    background-color: #136DDC;
                    text-align: center;
                    border-radius: 22px;
                    padding: 5px 12px;
                    color: white;
                    border: none;
                    cursor: pointer;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 16px;
                }

                .btn:hover{
                    background-color: #1b7ff9;
                    cursor: pointer;
                }
                .child-list{
                    list-style-type: none;
    
                    li {
                        display: flex;
                        align-items: center;
                        gap: 10px;
                        margin-top: 5px;
                    }  
                
                    li .icon {
                        height: 20px;
                        background: #363636;
                        border-radius: 5px;
                        padding: 5px;
                        display: flex;
                        width: 22px;
                    }

                    li .red {
                        color: #E25C00;
                        :hover {
                            color: rgb(237, 131, 61);
                            cursor: pointer;
                        }
                    }

                    li .yellow {
                        color: #FAE400;
                        :hover {
                            color: #f3eeb4;
                            cursor: pointer;
                        }
                    }

                    li .green {
                        color: #9EC000;
                        :hover {
                            color: #b2d905;
                            cursor: pointer;
                        }
                    }
                }
            }

            li:not(:last-child) { 
                margin-bottom: 4px;  
            }

            .bold {
                color: rgb(54, 53, 53);
                font-weight: 600;
            }
        }

        .sponzors{
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 10px;
        }
    }

    .start{
        // height: 10;
        flex-shrink: 1;
        padding: 20px 30px;
        background: #fff;
        border-radius: 15px;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    
        h1{
            text-align: center;
        }
    
        .animated-icon {
            width: 125px;
        }
    
        .select-players{
            margin-top: 50px;
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
        }
    
        .start-game {
            background-color: #136DDC;
            width: 50%;
            text-align: center;
            border-radius: 10px;
            padding: 10px 18px;
            color: white;
            border: none;
            cursor: pointer;
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
        }
    
        .disabled{
            user-select: none;
            pointer-events: none;
            opacity: .7;
            background: #699edf;
        }
    
        .start-game:hover {
            background-color: #267de7;
        }
    
        
        .player {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            margin-bottom: 15px;
            
            .player-wrapper{
                display: flex;
                position: relative;
                align-items: center;
            }
    
            .username {
                height: 40px;
                padding: 8px 16px;
                font-size: 14px;
                font-weight: 500;
                font-family: inherit;
                border-radius: 4px;
                border: 1px solid #CDD9ED;
                margin-left: 15px;
            }
    
            .invalid{
                border: 2px solid #eb445a;
    
                &:focus{
                    border: 2px solid #eb445a;
                    outline: none;
                }
            }
    
            .img-selector {
                width: 50px;
                height: 50px;
                border: 1px solid #CDD9ED;
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
            }
    
            .img-selector:hover {
                cursor: pointer;
                background-color: rgb(245, 245, 245);
                border: 1px solid #c5cfe0;
            }
    
            .img-selected {
                width: 30px;
                height: 30px;
            }
    
            .img-selected:hover{
                opacity: 0.8;
            }
    
            .icons-selector {
                position: absolute;
                top: 0;
                left: 0;
                transform: translateY(calc(-100% - 5px));
                border-radius: 15px;
                border: 1px solid #CDD9ED;
                background-color: #fff;
    
                display: flex;
                justify-content: space-between;
                align-items: center;
                gap: 15px;
                height: 100%;
                padding: 15px;
            }
    
            .selector-icon {
                height: 30px;
                width: 30px;
            }
    
            // .avatar1:hover, .avatar2:hover, .avatar3:hover, .avatar4:hover {
            //     cursor: pointer;
            //     opacity: 0.8;
            // }
        }
    }
}

@media only screen and (max-width: 1500px),
only screen and (max-height: 800px){
    li, span, .text, strong{
        font-size: 14px !important;
    }

    .title{
        font-size: 25px !important;

        span{
            font-size: 25px !important;
        }
    }

    h1{
        font-size: 25px;
    }

    .subpage{
        font-size: 20px !important;
    }

    .start-menu-modal{
        align-items: flex-start;
    
        .container{
            flex-direction: column;
            max-width: 800px;
        }
    }
}

</style>