<!-- eslint-disable vue/valid-v-model -->
<template>
    <div class="coding-practise-wrapper" :class="{ 'answered-correctly': answeredCorrectly, 'answered-uncorrectly': answeredCorrectly == false }">
        <div class="main-section">
            <div class="controls">
                <div class="icon hint" :class="{ disabled: showHint}" @click="toggleShowAnswer">
                    <font-awesome-icon icon="eye" class="hint"></font-awesome-icon>
                </div>
                <div class="icon" :class="{ disabled: showAnswer }" @click="showHint = !showHint">
                    <font-awesome-icon icon="question" class="ask"></font-awesome-icon>
                </div>
                <div class="icon check" :class="{ disabled: showAnswer || showHint }">
                    <font-awesome-icon icon="check" @click="checkAnswer"></font-awesome-icon>
                </div>
            </div>

            <div class="code" :key="showAnswer">
                <Codemirror
                    v-model:value="editorValue"
                    :options="cmOptions"
                    placeholder=""
                    @beforeChange="onBeforeChange">
                </Codemirror>
            </div>

            {{ inputAnswer }}
        </div>

        <div class="bottom-section">
            <!-- <div class="error">
                <span class="email-char">@</span> Missing "let" on variable creation and you forgot to round value in function with Math.round()
            </div> -->

            <div class="hint-section" v-if="showHint">{{ module.modules[id][lang].coding[index].hint }}</div>
        </div>
    </div>
</template>

<script>

import Codemirror from "codemirror-editor-vue3";
import "codemirror/addon/display/placeholder.js";
import "codemirror/mode/javascript/javascript.js";
import "codemirror/addon/display/placeholder.js";
import "codemirror/theme/monokai.css";
import modulesData from "../assets/modules.json";

export default {
    props: ["allCode", "rightAnswer", "index", "id", "lang"],
    components: { Codemirror },
    data() {
        return {
            cmCode: "",
            cmCodeWithAnswer: "",
            cmOptions: {
                mode: "text/javascript",
                theme: "monokai"
            },
            lineWithInput: 0,
            module: modulesData,
            inputAnswer: "",
            answeredCorrectly: null,
            showAnswer: false,
            showHint: false
        }
    },
    mounted(){
        this.cmCode = this.allCode
            .replace(":input", "");

        this.updateEditorStyleAndReadonlyPermission();

        this.cmCodeWithAnswer = this.allCode.replace(":input", this.rightAnswer);
    },
    computed: {
        editorValue: {
            get(){
                return this.showAnswer ? this.cmCodeWithAnswer : this.cmCode;
            },
            set(newValue){
                if(!this.showAnswer){
                    this.cmCode = newValue;
                }
            }
        }
    },
    methods: {
        onBeforeChange(instance, change){
            const ENTER_KEY_CODE = 10;
            const text = change.text.join('\n');
            const key = text.charCodeAt(text.length - 1);

            if(ENTER_KEY_CODE === key){
                change.cancel();
            }

            if (change.from.line !== this.lineWithInput) {
                change.cancel();
            }
        },
        checkAnswer(){
            const stringWithInput = this.allCode;
            const stringWithValue = this.cmCode;

            const inputIndex = stringWithInput.indexOf(':input');
            const prefix = stringWithInput.substring(0, inputIndex);
            const suffix = stringWithInput.substring(inputIndex + ':input'.length);
            const inputAnswer = stringWithValue.replace(prefix, '').replace(suffix, '');

            if(inputAnswer == this.rightAnswer){
                this.answeredCorrectly = true;
                this.module.modules[this.id].codeStatus[this.index] = 1;

                let value = 0;

                for(let i = 0; i < this.module.modules[this.id].codeStatus.length; i++) {
                    if(this.module.modules[this.id].codeStatus[i] == 1){
                        value++;
                    }
                }

                if(value == this.module.modules[this.id].codeStatus.length) {
                    this.$parent.moduleCompleted = true;
                } else {
                    this.$parent.moduleCompleted = false;
                }

            }else{
                this.answeredCorrectly = false;
                this.module.modules[this.id].codeStatus[this.index] = 0;

                let value = 0;

                for(let i = 0; i < this.module.modules[this.id].codeStatus.length; i++) {
                    if(this.module.modules[this.id].codeStatus[i] == 1){
                        value++;
                    }
                }

                if(value == this.module.modules[this.id].codeStatus.length) {
                    this.$parent.moduleCompleted = true;
                } else {
                    this.$parent.moduleCompleted = false;
                }
            }
        },
        toggleShowAnswer(){
            this.showAnswer = !this.showAnswer;

            if(!this.showAnswer){
                this.updateEditorStyleAndReadonlyPermission();
            }
        },
        updateEditorStyleAndReadonlyPermission(){
            this.lineWithInput = this.allCode
                .split("\n")
                .findIndex((line) => line.includes(":input"));

            this.$nextTick(() => {
                const editor = this.$el.querySelector(".CodeMirror").CodeMirror;
                const line = editor.getLineHandle(this.lineWithInput);
                editor.addLineClass(line, "wrap", "inputLine");
            });
        }
    }
}

</script>

<style>

.inputLine{
    background: #000 !important;
}

</style>

<style lang="scss" scoped>

input{
    background: #313131;
    border: 3px solid #1271E8;
    border-radius: 5px;
    padding: 5px 7px;
    color: #fff;
    
    &:focus{
        border: 3px solid #E25C00;
        outline: none;
    }
}

.disabled-input{
    border: 3px solid #505050 !important;
    color: rgb(187, 187, 187) !important;
}

.disabled{
    pointer-events: none;
    opacity: .5;
}

.coding-practise-wrapper{
    background: #252526;
    color: #fff;
    border-radius: 7px;
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;
    
    .main-section{
        width: 100%;
        display: flex;
    }

    .controls{
        padding: 7px;

        display: flex;
        flex-direction: column;
        gap: 10px;
        border-right: 4px solid #363636;

        .icon{
            background: #363636;
            border-radius: 5px;
            width: 43px;
            height: 43px;

            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 24px;
            transition: .3s ease-in-out;

            will-change: contents;

            &:hover{
                font-size: 30px;
            }

            .hint{
                color: #E25C00;
            }

            .ask{
                color: #FAE400;
            }

        }
        
        .check{
            border: 3px solid #9EC000;
            color: #9EC000
        }
    }

    .code{
        flex-grow: 1;
        padding: 7px;
    }

    .error{
        width: 100%;
        background: #290000;
        border-top: 3px solid #5C0100;
        color: #FE7F7F;
        font-size: 14px;
        padding: 10px 15px;

        border-radius: 0 0 7px 7px;

        .email-char{
            color: #fff;
            margin-right: 5px;
        }
    }

    .hint-section{
        width: 100%;
        background: #fffcd6;
        border: 3px solid #FAE400;
        color: #000000;
        font-size: 14px;
        padding: 10px 15px;

        border-radius: 0 0 5px 5px;

        .email-char{
            color: rgb(41, 41, 41);
            margin-right: 5px;
        }
    }
}

.answered-correctly{
    border-bottom: 6px solid #9EC000;
}

.answered-uncorrectly{
    border-bottom: 6px solid #eb445a;
}

</style>