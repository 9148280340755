<template>
    <div class="example">
        <pre><code class="white language-javascript">{{ formatData }}</code></pre>
    </div>

</template>

<script>
import Prism from "prismjs";
import "prismjs/themes/prism-okaidia.css";
import 'prismjs/components/prism-javascript.js';

    export default {
        props: ["code"],
        data() {
            return {
                formatData: "",
            }
        },
        mounted() {
            // this.formatData = this.code.replace(/\n/, "<br>");
            this.formatData = this.code.replace("<br>", `\n`);

            setInterval(() => {
                Prism.highlightAll();
            }, 1);
        }
    }
</script>

<style lang="scss" scoped>

pre{
    tab-size: 10;
    background-color: #252526 !important;

    &:first-child{
        margin-bottom: 0;
        padding-bottom: 0;
    }

    &:last-child{
        margin-top: 0;
        padding-top: 0;
    }
}

.white {
    background-color: #252526;
}

.example {
    margin-top: 15px;
    background-color: #252526;

    width: 100%;
    height: 100%;

    padding: 25px 20px;
    border-radius: 5px;
    border-left: 8px solid #699CD6;

    display: flex;
    align-items: center;    

    font-style: normal;
    font-weight: 400;
    font-size: 14px;
}


</style>