<!-- eslint-disable vue/multi-word-component-names -->
<template>
    <div class="player" :class="{ active: player.selectedAvatar == activePlayer + 1 }">
        <div class="user">
            <img :src="'../assets/img/avatars/avatar-' + player.selectedAvatar + '.png'" class="avatar">
            <span class="username" :class="colorText">{{ player.username }}</span>
        </div>
        <div class="score">
            <div v-for="i in inHouse" :key="i" :class="'point ' + color + '-fill'"></div>
            <div v-for="i in 4 - inHouse" :key="i" :class="'point'"></div>
        </div>
    </div>
</template>

<script>
    export default {
        props: ["player", "activePlayer", "color"],
        computed: {
            colorText(){
                return this.player.selectedAvatar == this.activePlayer + 1 ? this.color : '';
            },
            inHouse(){
                return this.player.gamePieces.filter(piece => piece.position > 40).length;
            }
        }
    }
</script>

<style lang="scss" scoped>

/*Players stats*/

.player {
    height: 25%;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    border: 3px solid transparent;
    border-radius: 20px;
}

.active{
    border: 3px solid #136DDC;
}

.user {
    display: flex;
    align-items: center;
    padding-left: 10px;
}

.avatar {
    width: 30px;
    height: 30px;
}

.username {
    padding-left: 10px;
    font-weight: bold;
}

.score {
    display: flex;
    margin: 10px;
    align-items: flex-end;
    gap: 3px;
}

.point {
    background: white;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    border: 1px solid #2C2C2C;
}

.blue {
    color: #699CD6;
}

.blue-fill {
    background-color: #699CD6;
}

.orange {
    color: #E25C00;
}

.orange-fill {
    background-color: #E25C00;
}

.green {
    color: #9EC000;
}

.green-fill {
    background-color: #9EC000;
}

.yellow {
    color: #FAE400;
}

.yellow-fill {
    background-color: #FAE400;
}

</style>