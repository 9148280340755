<template>
    <div class="playing-desk-content">
        <starting-space color="orange" top="true" left="true" :player="playerBasedOnAvatar(2)" />
        <starting-space color="green" top="true" left="" :player="playerBasedOnAvatar(3)" />
        <starting-space color="blue" top="" left="true" :player="playerBasedOnAvatar(1)" />
        <starting-space color="yellow" top="" left="" :player="playerBasedOnAvatar(4)" />

        <div class="top-section">
            <div class="col">
                <playing-field v-for="i in 5" :key="i" :field-id="i + 14" :avatar-standing="someoneStandingHere(i + 14)" @changeSelectedPiece="changeSelectedPiece"></playing-field>
            </div>

            <div class="col with-home">
                <playing-field field-id="20" :avatar-standing="someoneStandingHere(20)" @changeSelectedPiece="changeSelectedPiece"></playing-field>

                <div class="home home-green">
                    <playing-field v-for="i in 4" :key="i" :field-id="i + 40" :avatar-standing="someoneStandingHere(i + 40, true, 3)" @changeSelectedPiece="changeSelectedPiece"></playing-field>
                </div>
            </div>

            <div class="col">
                <playing-field v-for="i in 4" :key="i" :field-id="i + 20" :avatar-standing="someoneStandingHere(i + 20)" @changeSelectedPiece="changeSelectedPiece"></playing-field>
            </div>
        </div>

        <div class="center-section">
            <div class="left-section">
                <div class="col">
                    <playing-field v-for="i in 4" :key="i" :field-id="i + 10" :avatar-standing="someoneStandingHere(i + 10)" @changeSelectedPiece="changeSelectedPiece"></playing-field>
                </div>
    
                <div class="col">
                    <playing-field field-id="10" :avatar-standing="someoneStandingHere(10)" @changeSelectedPiece="changeSelectedPiece"></playing-field>
                    
                    <div class="home home-orange">
                        <playing-field v-for="i in 4" :key="i" :field-id="i + 40" :avatar-standing="someoneStandingHere(i + 40, true, 2)" @changeSelectedPiece="changeSelectedPiece"></playing-field>
                    </div>
                </div>
                <div class="col-wrapper">
                    <div class="col">
                        <playing-field v-for="i in 5" :key="i" :field-id="i + 4" :avatar-standing="someoneStandingHere(i + 4)" @changeSelectedPiece="changeSelectedPiece"></playing-field>
                    </div>
                </div>
            </div>

            <div class="right-section">
                <div class="col">
                    <playing-field v-for="i in 5" :key="i" :field-id="i + 24" :avatar-standing="someoneStandingHere(i + 24)" @changeSelectedPiece="changeSelectedPiece"></playing-field>
                </div>
    
                <div class="col">
                    <div class="home home-yellow">
                        <playing-field v-for="i in 4" :key="i" :field-id="i + 40" :avatar-standing="someoneStandingHere(i + 40, true, 4)" @changeSelectedPiece="changeSelectedPiece"></playing-field>
                    </div>
                    
                    <playing-field field-id="30" :avatar-standing="someoneStandingHere(30)" @changeSelectedPiece="changeSelectedPiece"></playing-field>
                </div>
    
                <div class="col">
                    <playing-field v-for="i in 4" :key="i" :field-id="i + 30" :avatar-standing="someoneStandingHere(i + 30)" @changeSelectedPiece="changeSelectedPiece"></playing-field>
                </div>
            </div>
        </div>

        <div class="bottom-section">
            <div class="col">
                <playing-field v-for="i in 4" :key="i" :field-id="i" :avatar-standing="someoneStandingHere(i)" @changeSelectedPiece="changeSelectedPiece"></playing-field>
            </div>

            <div class="col with-home">
                <div class="home home-blue">
                    <playing-field v-for="i in 4" :key="i" :field-id="i + 40" :avatar-standing="someoneStandingHere(i + 40, true, 1)" @changeSelectedPiece="changeSelectedPiece"></playing-field>
                </div>
                
                <playing-field field-id="40" :avatar-standing="someoneStandingHere(40)" @changeSelectedPiece="changeSelectedPiece"></playing-field>
            </div>

            <div class="col">
                <playing-field v-for="i in 5" :key="i" :field-id="i + 34" :avatar-standing="someoneStandingHere(i + 34)" @changeSelectedPiece="changeSelectedPiece"></playing-field>
            </div>
        </div>
    </div>
</template>

<script>

import PlayingField from './PlayingField.vue'

export default {
    components: { PlayingField },
    props: ["players"],
    methods: {
        playerBasedOnAvatar(selectedAvatar){
            return this.players.find(item => item.selectedAvatar == selectedAvatar);
        },
        someoneStandingHere(id, home, homeId){
            let someoneStanding = false;
            let canBeSelected = false;

            if(home){
                this.players.forEach(player => {
                    player.gamePieces.some(gamePiece => {
                        if(gamePiece.position == id){
                            if(player.selectedAvatar === homeId){
                                someoneStanding = player.selectedAvatar;
                                canBeSelected = gamePiece.canBeSelected;
                            }
                        }
                    })
                })
            }else{
                this.players.forEach(player => {
                    player.gamePieces.some(gamePiece => {
                        if(gamePiece.position == id){
                            someoneStanding = player.selectedAvatar;
                            canBeSelected = gamePiece.canBeSelected;
                        }
                    });
                });
            }

            return [someoneStanding, canBeSelected];
        },
        changeSelectedPiece(fieldId){
            // console.log("fieldId", fieldId);
            this.players.forEach(player => {
                let piece = player.gamePieces.find(piece => piece.position == fieldId);
                // console.log(piece);

                if(piece){
                    this.$parent.selectedPiece = player.gamePieces.findIndex(pieceInArray => pieceInArray === piece);
                }
            });
        }
    }
}

</script>

<style lang="scss">

.playing-desk-content{
    width: 100%;
    height: 100%;
    position: relative;
}

.home{
    background: #4d4d4d;
    
    display: flex;
    flex-direction: column;
    gap: 25px;

    padding: 10px;
    border-radius: 25px;

    .field{
        width: 55px;
        height: 55px;
    }
}

.home-green{
    background: #9EC000;
}

.home-blue{
    background: #699CD6;
}

.home-orange{
    background: #E25C00;
}

.home-yellow{
    background: #FAE400;
}

.top-section,
.bottom-section{
    position: absolute;
    left: 50%;
    transform: translate(-50%);

    display: flex;
    gap: 10px;

    z-index: 1;

    max-height: 35%;

    .col{
        display: flex;
        flex-direction: column;
        gap: 33px;
        align-items: center;
        justify-content: start;
        min-height: 100%;
        
        &.with-home{
            gap: 10px !important;
        }

        &:first-child{
            flex-direction: column-reverse;
        }
    }
}

.top-section{
    top: 0;
}

.center-section{
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    height: 30%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .left-section,
    .right-section{
        width: 35%;
        height: auto;

        position: relative;
        top: 0;
        left: 0;
        transform: translate(0, 0);

        display: flex;
        flex-direction: column !important;
        gap: 10px;

        .col{
            display: flex;
            gap: 33px;
            align-items: center;

            .home{
                flex-direction: row !important;
            }
        }
    }

    .left-section{
        align-items: flex-start;

        .col:last-child{
            flex-direction: row-reverse;
        }
    }

    .right-section{
        align-items: flex-end;

        .col:last-child{
            flex-direction: row-reverse;
        }

        .col{
            .home{
                flex-direction: row-reverse !important;
            }
        }
    }
}

.bottom-section{
    bottom: 0;
    align-items: flex-end;

    .home{
        flex-direction: column-reverse;
    }
    
    .col:first-child{
        flex-direction: column-reverse;
    }
}

// @media only screen and (max-width: 1230px){
@media only screen and (max-width: 1230px),
        only screen and (max-height: 960px){
    .home{
        gap: 30px;
        padding: 10px;

        .field{
            width: 45px;
            height: 45px;
        }
    }

    .top-section,
    .bottom-section{
        gap: 10px !important;

        .col{
            gap: 29.5px;
            
            &.with-home{
                gap: 15px !important;
            }
        }
    }

    .center-section{
        .left-section,
        .right-section{
            gap: 15px !important;

            .col{
                gap: 30.5px;
            }
        }
    }
}

@media only screen and (max-width: 1340px),
        only screen and (max-height: 850px){
    .field{
        width: 37px !important;
        height: 37px !important;
    }

    .home{
        gap: 10px !important;
    }

    .top-section,
    .bottom-section{
        gap: 10px !important;

        .col{
            gap: 12.5px;
            
            &.with-home{
                gap: 15px !important;
            }
        }
    }

    .center-section{
        .left-section,
        .right-section{
            gap: 15px !important;

            .col{
                gap: 15px;
            }
        }
    }
}

</style>