<!-- eslint-disable vue/multi-word-component-names -->
<template>
    <div class="container setting-container" v-click-outside-element="closePopup">
        <div style="display: flex; gap: 10px; align-items: center">
            <img style="height: 25px" :src="'../assets/img/icons/weilovka.jpg'">
            <img style="height: 33px" :src="'../assets/img/icons/europe.png'">
            <img style="height: 32px" :src="'../assets/img/icons/icodethefuture.png'">
        </div>
        <font-awesome-icon @click="toggleSettingPopup" tabindex="0" class="settings" style="width: 25px; height: 25px;" :icon="['fas', 'gear']"/>

        <div class="setting-popup" v-if="settingPopup">
            <ul>
                <li>
                    {{ langConfig[lang].volume}}
                    <div class="slidecontainer">
                        <input @click="updateVolume" type="range" v-model="volume" >  {{ volume }}
                      </div>
                </li>
                <li class="select-language">
                    {{ langConfig[lang].lang}}

                    <div class="flags">
                        <img :src="'../assets/img/icons/cz.png'" class="flag" :class="{ active: lang == 'cz'}" @click="changeLang('cz')">
                        <img :src="'../assets/img/icons/en.png'" class="flag" :class="{ active: lang == 'en'}" @click="changeLang('en')">
                        <img :src="'../assets/img/icons/es.png'" class="flag" :class="{ active: lang == 'es'}" @click="changeLang('es')">
                        <img :src="'../assets/img/icons/lt.png'" class="flag" :class="{ active: lang == 'lv'}" @click="changeLang('lv')">
                        <img :src="'../assets/img/icons/tr.png'" class="flag" :class="{ active: lang == 'tr'}" @click="changeLang('tr')">
                    </div>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import configLang from "../assets/config.json";

    export default {
        data() {
            return {
                lang: this.$parent.lang,
                langConfig: configLang,
                settingPopup: false,
                volume: this.$parent.volume,
            }
        },
        methods: {
            changeLang(lang){
                this.$parent.lang = lang;
                this.lang = lang;
            },
            toggleSettingPopup(){
                this.settingPopup = !this.settingPopup;
            },
            closePopup(){
                this.settingPopup = false;
            },
            updateVolume(){
                this.$parent.volume = this.volume;
            }
        },
    }
</script>

<style lang="scss" scoped>

*{
    margin: 0;
    padding: 0;
}

.container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.settings:hover {
    cursor: pointer;
}

.setting-popup{
    background: #fff;
    position: absolute;
    padding: 20px;
    top: 0;
    right: 0;
    transform: translateY(-110%);
    box-shadow: 0px 0px 10px rgb(68, 68, 68);
    border-radius: 20px;
    min-width: 300px;

    ul{
        list-style: none;

        li{
            border-bottom: 2px solid rgba(153, 153, 153, 0.486);
            padding: 10px 0;

            font-size: 20px;

            &:first-child{
                padding-top: 0;
            }

            &:last-child{
                padding-bottom: 0;
                border-bottom: none;
            }

            .vue-slider{
                margin-bottom: 20px;
            }
        }

        .select-language{
            display: flex;
            flex-direction: column;
            gap: 5px;

            .flags{
                display: flex;
                gap: 10px;
    
                .flag{
                    width: 50px;
                    border-radius: 5px;
                    border: 5px solid rgb(179, 179, 179);
    
                    &.active{
                        border-color: #225DFF;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 1100px){
    .setting-container{
        flex-direction: column;
        gap: 10px;
        
        div{
            flex-direction: column;
            gap: 10px;
            
            .settings{
                order: 1;
            }
        }
    }
}

// @media only screen and (max-width: 1230px){
//     .setting-container{
//         flex-direction: column;
        
//         div{
//             flex-direction: row;
//         }
//     }
// }

// @media only screen and (max-width: 1170px){
//     .setting-container{

//         div{
//             flex-direction: column;
//         }

//         .settings{
//             order: 1;
//         }
//     }
// }

</style>