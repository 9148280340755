<template>
    <div class="layout-bg">
        <div class="container">
            <h3>Select language</h3>
            <div class="flags">
                <img :src="'@/../assets/img/icons/cz.png'" class="flag" @click="changeLang('cz')">
                <img :src="'@/../assets/img/icons/en.png'" class="flag" @click="changeLang('en')">
                <img :src="'@/../assets/img/icons/es.png'" class="flag" @click="changeLang('es')">
                <img :src="'@/../assets/img/icons/lt.png'" class="flag" @click="changeLang('lv')">
                <img :src="'@/../assets/img/icons/tr.png'" class="flag" @click="changeLang('tr')">
            </div>
        </div>
    </div>
</template>

<script>

export default {
    data() {
        return {
            data: 0
        }
    },
    methods: {
        changeLang(lang){
            this.$parent.lang = lang;
            this.$parent.langSelect = false;
            this.$parent.modalIsOpen = true;
        },
    }
}

</script>

<style lang="scss" scoped>

.layout-bg{
    background: rgba(0, 0, 0, 0.842);
    width: 100%;
    height: 100%;

    overflow-y: auto;

    position: absolute;
    top: 0;
    left: 0;
    z-index: 999;

    display: flex;
    justify-content: center;
    align-items: center;
    
    padding: 30px;
}

.container {
    background-color: white;
    width: 300px;
    height: 150px;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;

    .flags {
        display: flex;
        gap: 10px;
        .flag{
            width: 50px;
            border-radius: 5px;
            border: 5px solid rgb(179, 179, 179);
            cursor: pointer;

            &:hover {
                border-color: #225DFF;
            }
        }
    }
}


</style>