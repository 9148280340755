<!-- eslint-disable vue/multi-word-component-names -->
<template>
    <div :class="{ lock: status == 'lock' }" class="task">
        <span class="name">{{ name }}</span>
        <font-awesome-icon v-if="status == 'success'" :icon="['far', 'circle-check']" class="success status"/>
        <font-awesome-icon v-if="status == 'during'" :icon="['far', 'circle-check']" class="status"/>
        <font-awesome-icon v-if="status == 'lock'" :icon="['fas', 'lock']" class="status"/>
    </div>
</template>

<script>
    export default {
        props: ["name", "status"]
    }
</script>

<style lang="scss" scoped>

.task {
    background-color: #EEEEEE;
    border-radius: 10px;
    width: 98%;
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 8px;
}

.name {
    padding-left: 15px;
    font-weight: bold;
    color: black;
}

.success {
    color: green !important;
}

.status {
    height: 25px;
    width: 25px;
    margin-right: 15px;
    color: black
}
</style>