<template>
    <h3 style="margin-bottom: 10px" class="title">{{ langConfig[lang].titleOverview}}</h3>
    <div class="container">
        <div class="left">
            <div class="main-dice">
                <img style="width: 70px; height: 70px;" :src="'../assets/img/dices/dice-' + (actualDice >= 1 && actualDice <= 6 ? actualDice : '7') + '.png'"/>
            </div>
            <div class="dice-stats">
                <img v-for="(val, index) in historyDice" :key="index" style="width: 25px; height: 25px;" :src="'../assets/img/dices/dice-' + val + '.png'"/>
            </div>
            <div class="dice-button">
                <button @click="rollDice" class="button" :class="{ 'disabled': gameSelectionInProcess }">{{ langConfig[lang].roll_the_dice}}</button>
            </div>
        </div>

        <div class="right">
            <div class="statistics">
                <player v-for="i in 4" :key="i" :player="this.$parent.players[i - 1]" :activePlayer="activePlayer"
                    :color="
                        players[i - 1].selectedAvatar == 1 ? 'blue' 
                        : players[i - 1].selectedAvatar == 2 ? 'orange'
                        : players[i - 1].selectedAvatar == 3 ? 'green'
                        : players[i - 1].selectedAvatar == 4 ? 'yellow'
                        : ''">
                </player>
            </div>
        </div>
    </div>
</template>

<script>
import configLang from "../assets/config.json";
import modulesData from "../assets/modules.json";

export default {
    props: ["actualDice", "players", "lang"],
    data() {
        return{
            langConfig: configLang,
            activePlayer: 0,
            module: modulesData,
            historyDice: [],
            count: null,
            gameSelectionInProcess: false,
            rollDiceCount: 0
        }
    },
    async created(){
        // await document.addEventListener("keyup", (e) => {
        //     if(e.key === "r" && !this.gameSelectionInProcess){
        //         this.rollDice();
        //     }
        // });

        // this.nextPlayer();

        // this.activePlayer = 3;
        // await this.kickOutOfStartingSpace();
        // this.players[this.activePlayer].gamePieces.find(item => item.position === 31).position = 44;
        // await this.kickOutOfStartingSpace();
        // this.players[this.activePlayer].gamePieces.find(item => item.position === 31).position = 42;
        // await this.kickOutOfStartingSpace();
        // this.players[this.activePlayer].gamePieces.find(item => item.position === 31).position = 28;
        // await this.kickOutOfStartingSpace();
        // this.players[this.activePlayer].gamePieces.find(item => item.position === 31).position = 29;

        // this.movePieceBy(42, 2);
        // this.moveWithOptions(6);
        
        // // this.players[this.activePlayer].gamePieces.find(item => item.position === 31).position = 29;


        // // await this.kickOutOfStartingSpace();
        // // this.players[this.activePlayer].gamePieces.find(item => item.position === 31).position = 26;

        // // await this.movePieceBy(26, 6);
        // await this.moveWithOptions(6);
        

        // await this.kickOutOfStartingSpace();
        // this.players[this.activePlayer].gamePieces.find(item => item.position === 31).position = 4;
        // await this.kickOutOfStartingSpace();
        // this.players[this.activePlayer].gamePieces.find(item => item.position === 31).position = 1;
        

        // await this.movePieceBy(1, 3);

        // await this.kickOutOfStartingSpace();

        // console.log(this.amIStandingThere(4));
        // this.movePieceBy(1, 3);
        // this.players[this.activePlayer].gamePieces.find(item => item.position === 1).position = 22;
        // await this.kickOutOfStartingSpace();
        // await this.kickOutOfStartingSpace();
        // this.canPieceGoThatFar(5, 6);

        // this.activePlayer = 2;
        // await this.kickOutOfStartingSpace();
        // this.players[this.activePlayer].gamePieces.find(item => item.position === 21).position = 19;
        // await this.kickOutOfStartingSpace();

        // // setTimeout(() => {
        // //     console.log("canPieceGoThatFar", this.canPieceGoThatFar(39, 5));
        // //     console.log("canPieceGoThatFar", this.canPieceGoThatFar(19, 6));
        // // }, 500);
    },
    methods: {
        async rollDice() {
            const learningModule = this.startLearningModule(0);
            if(learningModule === false){
                return;
            }

            if(this.gameSelectionInProcess){
                return;
            }

            // this.activePlayer = 3;

            // let previousDice = this.actualDice;
            let newDice;

            if(this.rollDiceCount >= 2 && this.players[this.activePlayer].gamePieces.filter(gamePiece => gamePiece.position === 0).length === 4){
                newDice = 6;
            }else{
                newDice = Math.floor(Math.random() * 6) + 1;
            }

            let playersOnStartingSpace = this.players[this.activePlayer].gamePieces.filter(piece => piece.position === 0);

            this.$parent.actualDice = newDice;
            this.$forceUpdate();

            if(playersOnStartingSpace.length === 4){
                if(newDice === 6){
                    if(this.isSomeoneStandingThere(this.players[this.activePlayer].startPosition)){
                        this.kickOutToStartingSpace(this.players[this.activePlayer].startPosition);
                    }

                    this.kickOutOfStartingSpace();
                }
            }else if(playersOnStartingSpace.length < 4 && playersOnStartingSpace.length > 0){
                if(newDice === 6){
                    if(this.isSomeoneStandingThere(this.players[this.activePlayer].startPosition)){
                        if(this.amIStandingThere(this.players[this.activePlayer].startPosition)){
                            await this.moveWithOptions(newDice);
                        }else{
                            this.kickOutToStartingSpace(this.players[this.activePlayer].startPosition);
                            this.kickOutOfStartingSpace();
                        }
                    }else{
                        this.kickOutOfStartingSpace();
                    }
                }else{
                    if(this.piecesOnPlayingDesk(this.activePlayer).length === 1){
                        let actualPosition = this.piecesOnPlayingDesk(this.activePlayer)[0].position;

                        if(actualPosition > 40){
                            if(actualPosition + newDice < 45){
                                await this.movePieceTo(actualPosition, actualPosition + newDice);
                            }
                        }else{
                            await this.movePieceTo(actualPosition, actualPosition + newDice);
                        }
                    }else{
                        await this.moveWithOptions(newDice);
                    }
                }
            }else{
                await this.moveWithOptions(newDice);
            }

            let playersOnStartingSpaceLen = this.players[this.activePlayer].gamePieces.filter(gamePiece => gamePiece.position === 0).length;

            if(playersOnStartingSpaceLen === 4){
                this.rollDiceCount++;
            }else if(newDice != 6){
                this.nextPlayer();
                this.rollDiceCount = 0;
            }

            this.historyDice.unshift(newDice);

            if(this.historyDice.length > 5){
                this.historyDice.pop();
            }
        },
        nextPlayer() {
            if(typeof this.activePlayer === "number"){
                this.activePlayer = (this.activePlayer + 1) % 4;
            }else{
                this.activePlayer = 0;
            }
        },
        kickOutOfStartingSpace(){
            const learningModule = this.startLearningModule(1);
            if(learningModule === false){
                return;
            }

            let piecesOnStartingSpace = this.players[this.activePlayer].gamePieces.filter(piece => piece.position === 0);
            let indexOfPlayerOnStartingSpace = this.players[this.activePlayer].gamePieces.findIndex(piece => piece === piecesOnStartingSpace[0]);

            this.players[this.activePlayer].gamePieces[indexOfPlayerOnStartingSpace].position = this.players[this.activePlayer].startPosition;
        },
        kickOutToStartingSpace(moveFrom){
            const learningModule = this.startLearningModule(3);
            if(learningModule === false){
                return;
            }

            this.players.forEach(player => {
                let piece = player.gamePieces.find(piece => piece.position === moveFrom);

                if(piece){
                    piece.position = 0;
                }
            })
        },
        areThereOptions(newDice){
            // let optionsToMoveWith = this.players[this.activePlayer].gamePieces.filter(piece => piece.position > 0 && piece.position <= 40);
            // let optionsToMoveWith = this.players[this.activePlayer].gamePieces.filter(piece => piece.position > 0 && piece.position <= 44);

            let optionsToMoveWith = this.players[this.activePlayer].gamePieces.filter(piece => {
                if(piece.position >= 41 && piece.position <= 44){
                    let newPosition = piece.position + newDice;
                    if(newPosition > 44){
                        return false;
                    }
                    return !this.isSomeoneStandingThere(newPosition);
                }

                if(piece.position > 0 && piece.position <= 40){
                    return true;
                }
            });

            // console.log("options to move with", optionsToMoveWith);

            optionsToMoveWith.forEach((optionToMoveWith, i) => {
                if(this.canPieceGoThatFar(optionToMoveWith.position, newDice) == false){
                    optionsToMoveWith.splice(i, 1);
                }
            });
            
            if(optionsToMoveWith.length > 1){
                return true;
            }else if(optionsToMoveWith.length === 1){
                this.movePieceBy(optionsToMoveWith[0].position, newDice);
                return false;
            }else{
                return false;
            }
        },
        async moveWithOptions(newDice){
            if(this.areThereOptions(newDice)){
                await this.giveOptionsToBeSelected(newDice);
    
                let actualPosition = this.players[this.activePlayer].gamePieces[this.$parent.selectedPiece].position;
                
                this.movePieceTo(actualPosition, actualPosition + newDice);
    
                this.$parent.selectedPiece = null;
            }
        },
        giveOptionsToBeSelected(newDice){
            this.$parent.players[this.activePlayer].gamePieces.forEach(piece => {
                if(piece.position > 0 && this.canPieceGoThatFar(piece.position, newDice) && (piece.position + newDice) < 45){    
                    piece.canBeSelected = true;
                }
            });
            this.gameSelectionInProcess = true;

            return new Promise(resolve => {
                let intervalId = setInterval(() => {
                    // console.log(this.$parent.selectedPiece);

                    if(typeof this.$parent.selectedPiece === "number" && (this.players[this.activePlayer].gamePieces[this.$parent.selectedPiece].position + newDice) < 45){
                        // console.log("selectnuto");
                        clearInterval(intervalId);

                        this.$parent.players[this.activePlayer].gamePieces.forEach(piece => {
                                piece.canBeSelected = false;
                        });
                        this.gameSelectionInProcess = false;

                        resolve();
                    }
                }, 50);
            });
        },
        async movePieceTo(moveFrom, moveTo){
            const learningModule = this.startLearningModule(2);
            if(learningModule === false){
                return;
            }

            this.gameSelectionInProcess = true;
            let gamePiece;
            let gamePiecePlayer;
            
            this.players.forEach(player => {
                let gamePieceFound = player.gamePieces.find(piece => piece.position == moveFrom);
                
                if(gamePieceFound){
                    gamePiece = gamePieceFound;
                    gamePiecePlayer = player;
                }
            });

            if(moveTo <= 40){
                let lastFieldForExactPlayer = gamePiecePlayer.startPosition - 1;
    
                if(lastFieldForExactPlayer >= moveFrom && lastFieldForExactPlayer < moveTo){
                    moveTo = moveTo - lastFieldForExactPlayer + 40;
                }
            }else if(moveTo > 40){
                if(gamePiecePlayer.startPosition != 1){
                    moveTo = moveTo - 40;
                }
            }

            if(!gamePiece){
                throw new Error("manual error - no game piece standing on this field")
            }

            await this.movePieceAnimation(gamePiece, moveTo);
            this.gameSelectionInProcess = false;
        },
        async movePieceAnimation(gamePiece, moveTo){
            let steps = 1;
            let goesThroughFieldsEnd = false;
            let goesToOwnHome = false;
            let normalMove = false;
            let playerThatOwnsGamePiece = null;

            if(moveTo > 44){
                return false;
            }

            // console.log(moveTo);

            if(moveTo > 40){
                // console.log("goes to own home");

                goesToOwnHome = true;

                playerThatOwnsGamePiece = this.players.find(player => {
                    return player.gamePieces.some(gamePieceOfPlayer => gamePieceOfPlayer.position === gamePiece.position);
                });

                let lastFieldOfPlayerFields = null;
                if(playerThatOwnsGamePiece.startPosition != 1){
                    lastFieldOfPlayerFields = playerThatOwnsGamePiece.startPosition - 1;
                }else{
                    lastFieldOfPlayerFields = 40;
                }

                steps = (lastFieldOfPlayerFields - gamePiece.position) + (moveTo - 40);
            }else if(moveTo > gamePiece.position){
                // console.log("normal move");

                normalMove = true;
                steps = moveTo - gamePiece.position;
            }else if(moveTo < gamePiece.position){
                // console.log("goes through fields end");

                goesThroughFieldsEnd = true;

                steps = 40 - gamePiece.position + moveTo;
            }
            
            return new Promise(resolve => {
                let i = 0;
                let interval = setInterval(() => {
                    if(i >= steps){
                        clearInterval(interval);
                        resolve();
                    }else{
                        let finalDestinationNow = false;
                        
                        if(i + 1 === steps){
                            finalDestinationNow = true;
                        }

                        if(finalDestinationNow){
                            if(this.isSomeoneStandingThere(gamePiece.position + 1)){
                                if(this.amIStandingThere(gamePiece.position + 1)){
                                    steps++;

                                    if(normalMove){
                                        playerThatOwnsGamePiece = this.players.find(player => {
                                            return player.gamePieces.some(gamePieceOfPlayer => gamePieceOfPlayer.position === gamePiece.position);
                                        });

                                        let endPosition = playerThatOwnsGamePiece.startPosition - 1;
                                        // console.log("++", gamePiece.position + 1, endPosition);

                                        if(gamePiece.position + 1 === endPosition){
                                            goesToOwnHome = true;
                                        }
                                    }
                                }else{
                                    this.kickOutToStartingSpace(gamePiece.position + 1);
                                }
                            }
                        }

                        if(goesToOwnHome){
                            const learningModule4 = this.startLearningModule(4);
                            if(learningModule4 === false){
                                return;
                            }

                            const learningModule5 = this.startLearningModule(5);
                            if(learningModule5 === false){
                                return;
                            }

                            // console.log("goes to own home");
                            if(playerThatOwnsGamePiece.startPosition - 1 === gamePiece.position){
                                if(this.isSomeoneStandingThere(41)){
                                    gamePiece.position = 42;
        
                                    // console.log("lol deme dělat debug so, gamePiece.position - ", gamePiece.position, " a this.isSome... - ", this.isSomeoneStandingThere(gamePiece.position + 1));
                                    while(!this.isSomeoneStandingThere(gamePiece.position + 1)){
                                        // console.log("while spuštěn");
                                        gamePiece.position++;
                                    }
                                }else{
                                    gamePiece.position = 41;
                                }
                                
                            }else{
                                gamePiece.position++;
                            }
                        }else if(goesThroughFieldsEnd && gamePiece.position === 40){
                            gamePiece.position = 1;
                        }else{
                            gamePiece.position++;
                        }

                        i++;
                    }
                }, 500);
            })
        },
        isSomeoneStandingThere(fieldId){
            let someoneStandingHere = false;

            this.players.forEach(player => {
                let fieldExists = player.gamePieces.find(piece => piece.position === fieldId);

                if(fieldExists){
                    someoneStandingHere = true;
                }
            });

            return someoneStandingHere;
        },
        amIStandingThere(fieldId){
            let iStandThere = false;
            let player = this.players.find(player => {
                return player.gamePieces.some(piece => piece.position === fieldId - 1);
            });

            let anyPiece = player.gamePieces.filter(piece => piece.position === fieldId);
            if(anyPiece.length > 0){
                iStandThere = true;
            }

            return iStandThere;
        },
        piecesOnPlayingDesk(selectedAvatar){
            return this.players[selectedAvatar].gamePieces.filter(piece => piece.position > 0);
        },
        decideMoveField(moveFrom, increase){
            let startingField;
            this.players.forEach(player => {
                let isTherePiece = player.gamePieces.find(piece => piece.position === moveFrom);

                if(isTherePiece){
                    startingField = player.startPosition;
                }
            });

            let lastField = startingField - 1;

            if(lastField === 0){
                lastField = 40;
            }

            let sum = moveFrom + increase;
            let res;

            // console.log(sum, lastField);
            // if(sum < lastField){
            //     res = sum - lastField + 40;
            // }else{
                res = sum;
            // }
            return res;
        },
        async movePieceBy(moveFrom, increaseBy){
            let moveTo = this.decideMoveField(moveFrom, increaseBy);

            await this.movePieceTo(moveFrom, moveTo);
        },
        canPieceGoThatFar(position, moveBy){
            let gamePiecePlayer;
            let gamePiece0 = this.players.filter(player => {
                let gamePiece = player.gamePieces.some(gamePiece => gamePiece.position === position);

                if(gamePiece){
                    gamePiecePlayer = player;
                }

                return gamePiece;
            });

            let lastPublicPosition;
            if(gamePiecePlayer.startPosition === 1){
                lastPublicPosition = 40;
            }else{
                lastPublicPosition = gamePiecePlayer.startPosition - 1;
            }

            let moveToByIncreasing = position + moveBy;

            if(lastPublicPosition >= position && lastPublicPosition <= moveToByIncreasing){
                let moveByInPublicArea = lastPublicPosition - position;
                let moveByInHome = moveBy - moveByInPublicArea;

                if(moveByInHome > 4){
                    return false;
                }else{
                    let positionInHome = 40 + moveByInHome;
                    let someoneStandingThere = gamePiecePlayer.gamePieces.find(gamePiece => gamePiece.position == positionInHome);

                    while(someoneStandingThere){
                        positionInHome++;
                        someoneStandingThere = gamePiecePlayer.gamePieces.find(gamePiece => gamePiece.position == positionInHome);

                        if(positionInHome >= 45){
                            return false;
                        }
                    }

                    return true;
                }
            }

            return true;
        },
        startLearningModule(index){
            if(this.module.modules[index].status != "success"){
                this.module.modules[index].status = "during";
                this.module.modules[index].isOpen = true;
                return false;
            }
        }
    }
}
    
</script>

<style lang="scss" scoped>
    .title {
        text-align: center;
    }

    .container {
        width: 100%;
    }

    .left {
        width: 50%;
        height: 180px;
        float: left;
    }

    .right {
        width: 50%;
        height: 180px;
        float: right;
    }


    /*Dice control*/
    .main-dice {
        height: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .dice-stats {
        height: 20%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .dice-button {
        height: 30%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .button {
        background-color: #136DDC;
        width: 90%;
        text-align: center;
        border-radius: 10px;
        padding: 10px 18px;
        color: white;
        border: none;
        cursor: pointer;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        user-select: none;
    }

    .button:hover {
        background-color: #267de7;
    }

    .disabled{
        background: #267de7bb;
        pointer-events: none;
    }

    .statistics {
        height: 100%;
    }

    @media only screen and (max-width: 1333px) {
        .right {
            width: 100%;
        }
        .left {
            width: 100%;
            height: auto;
            margin-bottom: 20px;
            display: flex;
            flex-direction: column;
            gap: 10px;
        }

        .dice-stats{
            display: none;
        }

        .statistics{
        //     display: flex;
        //     flex-wrap: wrap;
        }
    }

    // @media only screen and (max-width: 1230px) {
    //     .left{
    //         height: auto;
    //         margin-bottom: 20px;
    //         display: flex;
    //         flex-direction: column;
    //         gap: 10px;

    //         .dice-stats{
    //             display: none;
    //         }
    //     }
    // }

</style>