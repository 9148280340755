<template>
    <div v-if="avatarStanding" @click="changeSelectedPiece" class="field" :class="{
            'can-be-selected': avatarStanding[1],
            'starting-position': fieldId === 1 || fieldId === 11 || fieldId === 21 || fieldId === 31,
            'starting-position-1': fieldId === 1,
            'starting-position-2': fieldId === 11,
            'starting-position-3': fieldId === 21,
            'starting-position-4': fieldId === 31,
        }">
        <transition name="fade">
            <img v-if="avatarStanding[0]" :src="'../assets/img/avatars/avatar-' + avatarStanding[0] + '.png'">
        </transition>

        <!-- {{ fieldId }} -->
    </div>

    <div v-if="!avatarStanding" class="field"></div>
</template>

<script>
    export default {
        props: ["fieldId", "avatarStanding"],
        emits: ["changeSelectedPiece"],
        methods: {
            changeSelectedPiece(){
                if(this.avatarStanding[0]){
                    this.$emit("changeSelectedPiece", this.fieldId);
                }
            }
        }
    }
</script>

<style lang="scss" scoped>

.field{
    background: #fff;
    border-radius: 50%;

    width: 50px;
    height: 50px;

    display: flex;
    justify-content: center;
    align-items: center;

    flex-shrink: 0;

    padding: 10px;
    border: 3px solid rgb(36, 36, 36);

    cursor: pointer;
    
    img{
        width: 100%;
    }
}

.starting-position{
    border: none;
    font-weight: bold;
}

.starting-position-1{ background: #699CD6 !important; }
.starting-position-2{ background: #E25C00 !important; }
.starting-position-3{ background: #9EC000 !important; }
.starting-position-4{ background: #FAE400 !important; }

.can-be-selected{
    background: rgb(233, 233, 233);
    border: 3px solid #225DFF !important;
    transition: .3s;

    img{
        animation: bounce .8s ease-in-out infinite;
    }

    &:hover{
        background: #225dff5b;

        img{
            animation: stretch .8s ease-in-out infinite;
        }
    }
}

.fade-enter-active, .fade-leave-active {
    transition: opacity .4s;
    animation: bounce .4s ease-in-out;
}

.fade-enter, .fade-leave-to {
    opacity: 0;
}

@keyframes bounce{
    0%{
        transform: scale(1);
    }
    50%{
        transform: scale(1.2);
    }
    100%{
        transform: scale(1);
    }
}

@keyframes stretch{
    0%{
        transform: rotate(0deg);
    }
    30%{
        transform: rotate(50deg);
    }
    70%{
        transform: rotate(-30deg);
    }
    100%{
        transform: rotate(0deg);
    }
}

@media only screen and (max-width: 1230px),
    only screen and (max-height: 960px){
    .field{
        width: 43px;
        height: 43px;
    }

    .in-starting-space-field{
        width: 47px !important;
        height: 47px !important;
    }
}


@media only screen and (max-width: 1040px){
    .field{
        width: 43px;
        height: 43px;
    }

    .in-starting-space-field{
        width: 45px !important;
        height: 45px !important;
    }
}

</style>