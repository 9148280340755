<template>
    <div class="container">
        <h3 style="margin-bottom: 10px" class="title">{{ langConfig[lang].titleControl}}</h3>

        <div class="progress-bar">
            <progress :value="progress" max="100">75%</progress>
        </div>

        <div class="tasks">
            <task v-for="(task, index) in module.modules.length"
                :key="index"
                :id="index"
                :name="module.modules[index][lang].title"
                :class="module.modules[index].status"
                :status="module.modules[index].status"
                @click="openSection(index)"
            >
            </task>
        </div>
    </div>
</template>

<script>
import modulesData from "../assets/modules.json";
import configLang from "../assets/config.json";

    export default {
        props: ["progress", "lang"],
        data() {
            return {
                langConfig: configLang,
                module: modulesData,
            }
        },
        methods: {
            openSection(id) {
                if(this.module.modules[id].status != "lock") {
                    this.module.modules[id].isOpen = true;
                }
            }
        },
    }
</script>

<style lang="scss" scoped>
    
    .container{
        width: 100%;

        display: flex;
        flex-direction: column;
    }

    .title {
        text-align: center;
    }

    progress {
        width: 100%;
        height: 30px;
    }

    .tasks {
        max-height: 365px;
        overflow-y: auto;
    }

    .during:hover, .success:hover {
        cursor: pointer;
        opacity: 0.7;
    }

    ::-webkit-scrollbar {
        width: 10px;
        height: 10px;
    }

    ::-webkit-scrollbar-track {
        background: #f1f1f1;
        border-radius: 5px;
    }

    ::-webkit-scrollbar-thumb {
        background: #888;
        border-radius: 5px;
    }

    ::-webkit-scrollbar-thumb:hover {
        background: #555;
    }

    @media only screen and (max-width: 1230px),
            only screen and (max-height: 960px){
        .tasks {
            max-height: 200px !important;
        }
    }

</style>