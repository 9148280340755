/* eslint-disable vue/multi-word-component-names */
import { createApp } from 'vue'
import App from './App.vue'

import PlayingDesk from "./components/PlayingDesk.vue";
import StartingSpace from "./components/StartingSpace.vue";
import PlayingField from "./components/PlayingField.vue";
import GameOverview from "./components/GameOverview.vue";
import Progress from "./components/Progress.vue";
import Task from "./components/Task.vue";
import Player from "./components/Player.vue";
import Settings from "./components/Settings.vue";
import StartingMenuModal from "./components/StartingMenuModal.vue";
import CodingPractise from "./components/CodingPractise.vue";
import LearningModule from "./components/Settings.vue";
import CodeExample from "./components/CodeExample.vue";
import Alert from "./components/Alert.vue";
import SelectLang from "./components/SelectLang.vue";

import { basicSetup } from 'codemirror'
import VueCodemirror from 'vue-codemirror'

// import VueSlider from 'vue-slider-component';
import 'vue-slider-component/theme/default.css';
import vueClickOutsideElement from 'vue-click-outside-element';

import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { far } from '@fortawesome/free-regular-svg-icons';
import { faUserSecret } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import modulesData from "./assets/modules.json";

import "./scss/main.scss";

const app = createApp(App);

library.add(far);
library.add(fas);
library.add(faUserSecret)

app.component("font-awesome-icon", FontAwesomeIcon);

app.component("playing-desk", PlayingDesk);
app.component("starting-space", StartingSpace);
app.component("playing-field", PlayingField);
app.component("game-overview", GameOverview);
app.component("starting-menu-modal", StartingMenuModal);
app.component("progress-stats", Progress);
app.component("task", Task);
app.component("player", Player);
app.component("coding-practise", CodingPractise);
app.component("settings", Settings);
app.component("learning-module", LearningModule);
app.component("code-example", CodeExample);
app.component("alert", Alert);
app.component("select-lang", SelectLang);

// app.component('VueSlider', VueSlider);

app.use(vueClickOutsideElement);

app.mount("#app");
