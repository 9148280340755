<template>
    <transition name="slide-right">
        <div v-if="show" :class="'container '+ type">
            <div class="left">
                <div class="icon">
                    <font-awesome-icon
                        :icon="[
                            type == 'success' ? 'fas' : 'fas',
                            type == 'success' ? 'fa-circle-check' : type == 'danger' ? 'fa-triangle-exclamation' : 'fa-circle-info']"
                        class="status"/>
                </div>
                <div class="text">
                    <h3>{{title}}</h3>
                    <span>{{text}}</span>
                </div>
            </div>
            <div class="right">
                <span class="closebtn">&times;</span>
            </div>
        </div>
    </transition>
</template>

<script>
    export default {
        props: ["type", "title", "text"],
        data() {
            return {
                show: false,
                style: "",
            }
        },
        mounted() {
            this.updateMessage();
        },
        watch: {
            text(){
                this.updateMessage();
            }
        },
        methods: {
            updateMessage(){
                if(this.text != ""){
                    this.show = true;

                    setTimeout(() => {
                        this.show = false;
                    }, 5000);
                }
            }
        }
    }
</script>

<style lang="scss" scoped>

*{
    margin: 0;
    padding: 0;
}
.container {
    position: fixed;
    bottom: 15px;
    right: 15px;

    -webkit-box-shadow: -15px 20px 36px -27px rgba(0,0,0,0.65);
    -moz-box-shadow: -15px 20px 36px -27px rgba(0,0,0,0.65);
    box-shadow: -15px 20px 36px -27px rgba(0,0,0,0.65);
    
    width: 400px;
    padding: 20px 15px;
    border-radius: 5px;
    background-color: rgb(255, 255, 255);
    color: rgb(108, 108, 108);

    display: flex;
    justify-content: space-between;
    align-items: center;
}

.closebtn {
    font-size: 20px;
}

.closebtn:hover {
    cursor: pointer;
    color: #aeaebb;
}

.left {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.icon {
    height: 30px;
    margin-right: 15px;
}

.status {
    height: 30px;
}

.text {
    color: rgb(49, 47, 47);
}

.success {
    border-left: solid rgb(77,203,101) 8px;
    color: rgb(77,203,101);
}
.danger {
    border-left: solid rgb(128, 0, 0) 8px;
    color: rgb(128, 0, 0);
}
.info {
    border-left:solid rgb(60, 131, 223) 8px;
    color: rgb(60, 131, 223);
}

/* CSS */
.slide-right-enter-active {
    animation: slide-in-right .3s;
}

.slide-right-leave-active {
    animation: slide-out-right .3s;
}

@keyframes slide-in-right {
    from {
        transform: translateX(100%);
    }
    to {
        transform: translateX(0);
    }
}

@keyframes slide-out-right {
    from {
        transform: translateX(0);
    }
    to {
        transform: translateX(100%);
    }
}

</style>