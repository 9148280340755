<template>
    <div class="layout-bg">
        <div class="container">
            <div class="header">
                <h2 class="title">{{ module.modules[name][[lang]].title}}</h2>
                <font-awesome-icon @click="closeModal" class="cancel" icon="xmark" />
            </div>

            <p style="margin-bottom: 20px;" class="text">{{ module.modules[name][lang].subtitle}}</p>

            <!-- Learning -->

            <div v-for="(modules, index) in module.modules[name][lang].sections.length"
                v-show="currentPage[0] == module.modules[name][lang].sections[modules-1].page"
                v-if="currentPage[0] != null"
                :key="index" 
                class="module">

                <h3 class="subtitle blue">
                    {{ module.modules[name][lang].sections[modules-1].name }}
                    <font-awesome-icon @click="playVoice(module.modules[name][lang].sections[modules-1].voice)" class="sound" icon="fa-volume-high" />
                </h3>
        
                <p class="text">
                    {{ module.modules[name][lang].sections[modules-1].description }}
                </p>
        
                <code-example :code="module.modules[name][lang].sections[modules-1].code">
                    <!-- {{ module.modules[name][lang].sections[modules-1].code }} -->
                </code-example>
        
                <ul class="list">
                    <li v-for="(list, index) in module.modules[name][lang].sections[modules-1].help" :key="index">
                        <strong>{{ module.modules[name][lang].sections[modules-1].help[index].tag }}</strong>
                        - 
                        {{ module.modules[name][lang].sections[modules-1].help[index].text }}
                    </li>
                </ul>

                <span style="margin: 10px 0px 15px 0px">{{module.modules[name][lang].sections[modules-1].other}}</span> 
            </div>

            <!-- Coding -->

            <div v-for="(modules, indexMain) in module.modules[name][lang].coding.length"
                v-show="currentPage[1] == module.modules[name][lang].coding[modules-1].page"
                v-if="coding"
                :key="indexMain" 
                class="module">

                <h3 class="subtitle blue">
                    Zadání 
                </h3>
        
                <ol class="list">
                    <li v-for="(task, index) in module.modules[name][lang].coding[indexMain].task.length" :key="index">
                        {{ module.modules[name][lang].coding[indexMain].task[task-1] }}
                    </li>
                </ol>

                <coding-practise :lang="lang" :id="name" :index="indexMain" :all-code="module.modules[name][lang].coding[indexMain].code" :right-answer="module.modules[name][lang].coding[indexMain].answer" />

            </div>

            <div class="footer" :class="{ 'v-if-coding-is-false': !coding }">
                <span v-if="coding == true" @click="backLesson" class="backLesson"><font-awesome-icon icon="fa-arrow-left" /> {{ langConfig[lang].backLesson}}</span>
                <div class="buttons">
                    <span> {{ currentPage[section] }} / {{ maxPage[section] }} &nbsp</span>
                    <button v-if="currentPage[section] > 1" @click="backPage" class="continue-btn">{{ langConfig[lang].back}}</button>
                    <button v-if="currentPage[section] != maxPage[section]" @click="nextPage" class="continue-btn">{{ langConfig[lang].continue}}</button>
                    <button v-if="currentPage[0] == maxPage[0]" @click="practisePage" class="continue-btn">{{ langConfig[lang].practise}}</button>
                    <button v-if="currentPage[1] == maxPage[1] && coding == true" @click="completePage" :class="moduleCompleted ? 'continue-btn' : 'continue-btn disabled'">{{ langConfig[lang].complete}}</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Prism from "prismjs";
    import "prismjs/themes/prism-okaidia.css";
    import 'prismjs/components/prism-javascript.js';
    import modulesData from "../assets/modules.json";
    import configLang from "../assets/config.json";
    import { Howl, Howler } from 'howler';

    export default {
        props: ["name","pages", "lang", "volume", "statusM"],
        data() {
            return {
                module: modulesData,
                langConfig: configLang,
                moduleCompleted: false,
                previousVoice: null,
                currentPage: [1, 1],
                coding: false,
                section: 0, //0 - learning, 1 - coding
                maxPage: [Math.round(modulesData.modules[this.name][this.lang].sections.length / 2), Math.round(modulesData.modules[this.name][this.lang].coding.length / 2)]
            }
        },
        mounted() {
            Prism.highlightAll();
            this.changeBodyOverflow();
        },
        methods: {
            playVoice(name) {

                let volume = this.volume / 100;

                if (this.previousVoice) {
                    this.previousVoice.stop();
                }
                const voice = new Howl({
                    src: ["../assets/mp3/" + name],
                });
                voice.volume(volume);
                voice.play();
                this.previousVoice = voice;
            },
            nextPage() {
                if(this.currentPage[this.section] < this.maxPage[this.section]) {
                    this.currentPage[this.section] += 1;
                    if (this.previousVoice) {
                        this.previousVoice.stop();
                    }
                }
            },
            backPage() {
                if(this.currentPage[this.section] >= this.maxPage[this.section]) {
                    this.currentPage[this.section] -= 1;
                    if (this.previousVoice) {
                        this.previousVoice.stop();
                    }
                }
            },
            practisePage() {
                this.currentPage[this.section] = null;
                this.coding = true;
                this.section = 1;
                if (this.previousVoice) {
                    this.previousVoice.stop();
                }
            },
            backLesson() {
                this.currentPage[0] = this.maxPage[0];
                this.coding = false;
                this.section = 0;
            },
            closeModal() {
                // console.log(this.module.modules[this.name].isOpen);
                this.module.modules[this.name].isOpen = false;
                // console.log(this.module.modules[this.name].isOpen);
                if (this.previousVoice) {
                    this.previousVoice.stop();
                }

                this.changeBodyOverflow();
            },
            completePage() {
                if(this.module.modules[this.name].status != "success") {
                    this.$parent.progress += 100 / (this.module.modules.length);
                }
                this.module.modules[this.name].status = "success";
                this.module.modules[this.name].isOpen = false;

                this.changeBodyOverflow();
            },
            changeBodyOverflow(){
                if(this.module.modules[this.name].isOpen){
                    document.body.style.overflowY = 'hidden';
                }else{
                    document.body.style.overflowY = 'auto';
                }
            }
        },
        watch: {
            modalIsOpen(){
                this.changeBodyOverflow();
            }
        }
    }
</script>

<style lang="scss" scoped>

// .readonly-line {
//   pointer-events: none; /* Disable pointer events to make the text unselectable */
//   opacity: 0.5;         /* Reduce opacity to make the text appear grayed out */
// }

*{
    font-family: 'Open Sans', sans-serif;
    // filter: blur(0);
}

*.Codemirror .readOnly {
    background-color: #cccccc;
}

.cancel {
    color: red;
}

.disabled {
    opacity: 0.5;
    pointer-events: none;
    cursor: not-allowed;
}

.cancel:hover {
    color: rgb(252, 124, 124);
    cursor: pointer;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.backLesson {
    color: rgb(61, 59, 59);
}
.backLesson:hover {
    cursor: pointer;
    color: #136DDC;
    transition: 0.7s;
}

.text {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
}

.footer {
    display: flex;
    align-items: center;
    justify-content: space-between;  
}

.footer.v-if-coding-is-false {
    justify-content: flex-end;
}

.buttons {
    display: flex;
    align-items: center;
}

.title {
    font-weight: 600;
    line-height: 32px;
}

.subtitle {
    font-weight: 400;
    line-height: 32px;
    display: flex;
    align-items: center;
}

.sound {
    width: 25px; 
    margin-left: 5px;
}

.sound:hover{
    width: 27px; 
    cursor: pointer;
    transition-duration: 0.3s;
}

.blue {
    color: #0072FF;
}

.bold {
    font-weight: 600;
}

.container {
    // position: fixed;
    z-index: 998 !important;
    // top: 50%;
    // left: 50%;
    // transform: translate(-50%, -50%);
    padding: 25px;

    background-color: white;

    width: 100%;
    max-width: 1200px; 
    border-radius: 25px;
    filter: drop-shadow(0px 8px 10px rgba(0, 0, 0, 0.25));
}

.module {
    width: 100%;
    margin-bottom: 0px;

    display: flex;
    flex-direction: column;
    align-items: start;
}

.list {
    margin-top: 10px;
    margin-left: 15px;
}

.continue-btn {
    float: right;
    background-color: #136DDC;
    text-align: center;
    margin-left: 6px;
    border-radius: 22px;
    padding: 10px 18px;
    color: white;
    border: none;
    cursor: pointer;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
}

.continue-btn:hover {
    background-color: #267de7;
}

.layout-bg{
    background: rgba(0, 0, 0, 0.842);
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 999;

    display: flex;
    justify-content: center;
    align-items: center;

    overflow-y: auto;
}

@media only screen and (max-width: 1500px),
only screen and (max-height: 800px){
    // li, span, .text, strong{
    //     font-size: 14px !important;
    // }

    // .title{
    //     font-size: 25px !important;

    //     span{
    //         font-size: 25px !important;
    //     }
    // }

    // h1{
    //     font-size: 25px;
    // }

    // .subpage{
    //     font-size: 20px !important;
    // }

    .layout-bg{
        align-items: flex-start;
    }

    .container{
        max-width: 800px !important;
    }
}

</style>