<template>
    <div>
        <select-lang v-if="langSelect"></select-lang>
        <starting-menu-modal :lang="lang" v-show="modalIsOpen" :modal-is-open="modalIsOpen" @close="modalIsOpen = !modalIsOpen"></starting-menu-modal>

        <learning-module :volume="volume" :lang="lang" v-if="module.modules[0].isOpen == true" name="0"></learning-module>
        <learning-module :volume="volume" :lang="lang" v-if="module.modules[1].isOpen == true" name="1"></learning-module>
        <learning-module :volume="volume" :lang="lang" v-if="module.modules[2].isOpen == true" name="2"></learning-module>
        <learning-module :volume="volume" :lang="lang" v-if="module.modules[3].isOpen == true" name="3"></learning-module>
        <learning-module :volume="volume" :lang="lang" v-if="module.modules[5].isOpen == true" name="5"></learning-module>
        <learning-module :volume="volume" :lang="lang" v-if="module.modules[4].isOpen == true" name="4"></learning-module>
        
        <div class="main-container">
            <div class="playing-desk card disable-copy">
                <playing-desk :players="players"></playing-desk>
            </div>

            <div class="game-controlls-and-stats disable-copy">
                <div class="card card-game-overview">
                    <game-overview :lang="lang" :players="players" :actual-dice="actualDice"></game-overview>
                </div>
                <div class="card card-progress-stats">
                    <progress-stats :lang="lang" :progress="progress"></progress-stats>
                </div>
                <div class="card card-settings">
                    <settings></settings>
                </div>
            </div>
        </div>


        <alert :type="alert.type" :title="alert.title" :text="alert.text" />

        <div class="too-small-device">
            <div class="message">
                {{ langConfig[lang].smallDevice }}
            </div>
        </div>
    </div>
    
    <div class="credit">
        <span class="credits allow-copy !important">{{ langConfig[lang].credits }}</span>
    </div>

    <transition name="fade">
        <div class="someone-won-wrapper" v-if="someoneWon">
            <div class="someone-won">
                <font-awesome-icon icon="fa-crown" class="crowd-icon" />
                <h3>{{ langConfig[lang].winner }} {{ players.find(player => player.selectedAvatar === someoneWon).username }}</h3>
                <img :src="'./assets/img/avatars/avatar-' + someoneWon + '.png'" class="avatar">
                <div class="buttons">
                    <button class="btn btn-primary" @click="reloadPage">{{ langConfig[lang].playAgain }}</button>
                    <button class="btn btn-secondary">{{ langConfig[lang].continueGame }}</button>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
import LearningModule from './components/LearningModule.vue';
import modulesData from "./assets/modules.json";
import configLang from "./assets/config.json";

export default {
    components: { LearningModule },
    data() {
        return {
            alert: {
                type: "",
                title: "",
                text: ""
            },
            lang: "en",
            langSelect: true,
            langConfig: configLang,
            modalIsOpen: false,
            module: modulesData,
            actualDice: 7,
            volume: 50,
            code: '',
            progress: 0,
            players: [
                {
                    selectedAvatar: 1,
                    startPosition: 1,
                    username: "",
                    gamePieces: [
                        {
                            position: 0,
                            canBeSelected: false
                        },
                        {
                            position: 0,
                            canBeSelected: false
                        },
                        {
                            position: 0,
                            canBeSelected: false
                        },
                        {
                            position: 0,
                            canBeSelected: false
                        },
                    ]
                },
                {
                    selectedAvatar: 2,
                    startPosition: 11,
                    username: "",
                    gamePieces: [
                        {
                            position: 0,
                            canBeSelected: false
                        },
                        {
                            position: 0,
                            canBeSelected: false
                        },
                        {
                            position: 0,
                            canBeSelected: false
                        },
                        {
                            position: 0,
                            canBeSelected: false
                        },
                    ]
                },
                {
                    selectedAvatar: 3,
                    startPosition: 21,
                    username: "",
                    gamePieces: [
                        {
                            position: 0,
                            canBeSelected: false
                        },
                        {
                            position: 0,
                            canBeSelected: false
                        },
                        {
                            position: 0,
                            canBeSelected: false
                        },
                        {
                            position: 0,
                            canBeSelected: false
                        },
                    ]
                },
                {
                    selectedAvatar: 4,
                    startPosition: 31,
                    username: "",
                    gamePieces: [
                        {
                            position: 0,
                            canBeSelected: false
                        },
                        {
                            position: 0,
                            canBeSelected: false
                        },
                        {
                            position: 0,
                            canBeSelected: false
                        },
                        {
                            position: 0,
                            canBeSelected: false
                        },
                    ]
                },
            ]
        };
    },
    mounted() {
        // this.modalIsOpen = true;
        this.changeBodyOverflow();
    },
    methods: {
        reloadPage(){
            window.location.reload();
        },
        changeBodyOverflow(){
            if(this.modalIsOpen){
                document.body.style.overflowY = 'hidden';
            }else{
                document.body.style.overflowY = 'auto';
            }
        }
    },
    computed: {
        someoneWon(){
            let someoneWon = false;

            let playerWinner = this.players.filter(player => {
                return player.gamePieces.some(piece => piece.position > 40);
            });

            if(playerWinner.length > 0){
                // console.log(playerWinner[0].gamePieces.filter(piece => piece.position > 40));
    
                if(playerWinner[0].gamePieces.filter(piece => piece.position > 40).length === 4){
                    someoneWon = playerWinner[0].selectedAvatar;
                }
            }

            return someoneWon;
        }
    },
    watch: {
        modalIsOpen(){
            this.changeBodyOverflow();
        }
    }
}

</script>

<style lang="scss">

    .disable-copy {
        -webkit-user-select: none; /* Safari */
        -moz-user-select: none; /* Firefox */
        -ms-user-select: none; /* IE10+/Edge */
        user-select: none; /* Standard syntax */
    }

    .allow-copy {
        -webkit-user-select: text; /* Safari */
        -moz-user-select: text; /* Firefox */
        -ms-user-select: text; /* IE10+/Edge */
        user-select: text; /* Standard syntax */
        &::selection {
            color: #E25C00;
        }
    }

</style>

<style lang="scss" scoped>
    // global styles
    *{
        font-family: 'Open Sans', sans-serif;
    }
    .main-container{
        max-width: 1540px;
        margin: 0 auto;

        display: flex;
        max-height: 984px !important;
        gap: 30px;

        padding-top: 30px;
        padding-bottom: 30px;

        padding-left: 20px;
        padding-right: 20px;
    }

    .credit {
        width: 100%;
        height: auto;
        text-align: right;
        padding-right: 30px;
        .credits {
            font-weight: 600;
            color: #fff;
        }
    }

    .demo-window-wrapper{
        position: fixed;
        background: rgba(3, 3, 3, 0.623);
        width: 100vw;
        height: 100vh;
        top: 0;
        left: 0;
        z-index: 999999;
        text-align: center;

        .demo-window{
            background: #fff;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
    
            z-index: 99999999;
            padding: 20px;
            border-radius: 20px;

            display: flex;
            flex-direction: column;
            gap: 20px;
            align-items: center;

            button {
                background-color: #136DDC;
                width: 90%;
                text-align: center;
                border-radius: 10px;
                padding: 10px 18px;
                color: white;
                border: none;
                cursor: pointer;
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                user-select: none;
            }
        }
    }

    .fade-enter-active, .fade-leave-active {
        transition: opacity .5s;
    }

    .fade-enter, .fade-leave-active {
        opacity: 0;
    }

    .playing-desk{
        flex-shrink: 0;
        width: 924px;
        height: 924px;
    }

    .game-controlls-and-stats{
        flex-basis: 100%;

        display: flex;
        flex-direction: column;
        gap: 30px;

        .card-game-overview{
            flex-shrink: 1;
        }

        .card-progress-stats{
            height: 100%;
            // flex-grow: 1;
        }

        .card-settings{
            flex-shrink: 1;
        }
    }

    .too-small-device{
        background: rgba(0, 0, 0, 0.9);
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;

        display: none;

        z-index: 999999;

        background-image: url("../public/assets/img/background.png");

        .message{
            background: #fff;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            border-radius: 25px;
            padding: 20px 25px;
            width: calc(100% - 50px);
            text-align: center;
        }
    }

    .someone-won-wrapper{
        background: rgba(61, 61, 61, 0.524);
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        z-index: 999999999999;
        
        .someone-won{
            background: #fff;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
    
            padding: 20px;
            border-radius: 20px;
    
            box-shadow: 5px 5px 20px rgb(99, 99, 99);
    
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 20px;
    
            text-align: center;
    
            .crowd-icon{
                color: #136DDC;
                font-size: 50px;
            }
    
            h3{
                font-weight: 700;
                font-size: 30px;
            }
    
            .avatar{
                width: 100px;
            }
    
            .buttons{
                display: flex;
                gap: 10px;
    
                .btn{
                    border: 0;
                    outline: 0;
                    padding: 10px 20px;
    
                    color: #fff;
    
                    border-radius: 5px;
    
                    font-weight: 600;
                }
    
                .btn-primary{
                    background: #136DDC;
                }
    
                .btn-secondary{
                    background: rgba(65, 65, 65, 0.508);
                    cursor: not-allowed;
                }
            }
        }
    }

    .fade-enter-active,
    .fade-leave-active {
        transition: opacity 0.5s;
    }

    .fade-enter-from,
    .fade-leave-to {
        opacity: 0;
    }

    @media only screen and (max-width: 1230px),
            only screen and (max-height: 960px){
        .card{
            padding: 20px !important;
        }

        .playing-desk{
            width: 800px !important;
            height: 800px !important;
        }

        h3.title{
            font-size: 20px;
        }
    }
 
    @media only screen and (max-width: 1340px),
            only screen and (max-height: 850px){
        .playing-desk{
            width: 600px !important;
            height: 600px !important;
        }
    }

    @media only screen and (max-width: 1000px){
        .main-container{
            flex-direction: column;
            max-width: 700px;
        }

        .credit {
            padding-bottom: 15px;
            padding-right: 0px;
            text-align: center;
        }

        .playing-desk{
            width: 100% !important;
            height: calc(700px - 60px) !important;
        }
    }
    
    @media only screen and (max-width: 769px){
        .too-small-device{
            display: block;
        }
    }
</style>